import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "./plugins/elementUI" 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/js/pxrem.js'
import { setDomFontSize } from '@/assets/js/pxrem'
setDomFontSize()

// 引入api请求接口
// import API from '@/api'
// Vue.prototype.$API = API
// 引入MOCK
import "./mock/index.js"

// import 'lib-flexible'
Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
