import axios from 'axios'
import { Loading } from 'element-ui';
var host // 声明一下 基础路径

// process.env是Nodeis提供的一个API，它返回一个包含用户环境信息的对象。
// 通过NODE_ENV 判断当前环境是生产环境(production)还是开发环境(development)自动切换不同域名
// 暂时开发环境和生产环境 所以域名暂时一样

if (process.env.NODE_ENV == "development") {
  // 开发环境
  host = "https://api.oioweb.cn/api/common//"
} else {
  host = "http://bufantec.com/api"
  // 生产环境/线上环境

}
// 创建爱弄一个axios实例
const service = axios.create({
  baseURL: host,
  // baseURL: 'https://www.fastmock.site/mock/f37cb3d38c42bd4c2de95002461b4ec9/cmeb-multi',
  timeout: 5000, // 请求延时时间 5秒
  withCredentials: false // 表示跨域请求时是否需要使用凭证
})
let loading
function openLoding() {
  loading = Loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
}
function closeLoding() {
  loading.close()
}
// 请求拦截器
service.interceptors.request.use(
  // openLoding(),
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
// closeLoding(),
  response => {
    return response.data
  },
  error => {
    console.log('err' + error)
    //   this.$message({
    //     message: error.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    return Promise.reject(error)
  }
)

export default service
