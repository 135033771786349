// product管理模块的数据
import request from '@/utils/request'

// 获取分类列表
export function reqCateGoryList() {
  return request({
     url: '/info',
    method: 'get'
  })
}
// 获取分类列表
export function goodAll() {
  return request({
     url: '/api/slider/',
    method: 'post'
  })
}

// 获取分类列表
export function freeAPI() {
  return request({
     url: '/OneDayEnglish',
    method: 'get'
  })
}