<template>
  <div class="">
    <div class="footer" :style="{ color: FontColor }">
      <div>
        武陟圖書館館藏古籍資源資料庫 Copyrights © 2022 Library of Wuzhi China
      </div>
      <div>技術支持：河南赢熙信息技术有限公司</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    FontColor:String
  },
  components: {},
  data () {
    return {

    }
  },
  computed: {},//计算属性
  watch: {},//监听
  methods: {

  },
  created () {

},//初始化
  mounted () {}//页面挂载
}
</script>

<style scoped lang="scss">

.footer {
    position: absolute;
    left: 100px;
    bottom: 50px;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    // color: #fff;
    letter-spacing:1pt; 
    line-height: 40px;
  }
</style>